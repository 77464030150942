"use client";

import { createI18nClient } from "next-international/client";

export const {
  useI18n,
  useScopedI18n,
  I18nProviderClient,
  useChangeLocale,
  useCurrentLocale,
} = createI18nClient({
  en: () => import("./en"),
  es: () => import("./es"),
  zh: () => import("./zh"),
  fr: () => import("./fr"),
  it: () => import("./it"),
  jp: () => import("./jp"),
  ko: () => import("./ko"),
  ms: () => import("./ms"),
  ru: () => import("./ru"),
  // Add more locales here
  // fr: () => import('./fr'),
});
